#########################################################################
### QuestionnaireTriggersObject
#########################################################################
class QuestionnaireTriggersObject

    constructor: (questionnaireItems, questionnaireSegments, respondentInfo) ->
        @questionnaireItems     = questionnaireItems
        @questionnaireSegments  = {}
        @respondentInfo         = respondentInfo
        @disableRespondent      = false

        if questionnaireSegments
            for segmentGroup in questionnaireSegments
                for segment in segmentGroup.items
                    @questionnaireSegments[segment.id] = {id:segment.id, text:segment.text, parentid:segmentGroup.id, parenttext:segmentGroup.text}

    disableRespondentChecking: ->
        @disableRespondent = true


    getQuestionnaireItem: (id) ->
        for item in @questionnaireItems
            if item.id == id
                return {item:item, parent:null}
            else
                if item.hasOwnProperty('items')
                    for subitem in item.items
                        if subitem.id == id
                            return {item:subitem, parent:item}
        return {item:null, parent:null}


    isTriggerConditionMet: (relationshipEntry, respondentResponses) ->
        if relationshipEntry.item == 'RESPONDENT'
            if @disableRespondent
                return true
            if not @respondentInfo
                return false
            if not @questionnaireSegments.hasOwnProperty(relationshipEntry.subitem)
                return false
            respondentSegment = @questionnaireSegments[relationshipEntry.subitem]
            if not @respondentInfo.hasOwnProperty(respondentSegment.parenttext)
                return false
            if @respondentInfo[respondentSegment.parenttext] == respondentSegment.text
                return true
            else
                return false
        else
            item   = this.getQuestionnaireItem(relationshipEntry.item)['item']
            parent = this.getQuestionnaireItem(relationshipEntry.item)['parent']
            if item
                if relationshipEntry.action == 'SELECTED'
                    if not respondentResponses.hasOwnProperty(relationshipEntry.item)
                        return false
                if item.type == 'QUESTION'
                    if parent
                        subindicator = relationshipEntry.subitem.substr(33, 32)
                        if respondentResponses.hasOwnProperty(relationshipEntry.item)
                            if respondentResponses[relationshipEntry.item].hasOwnProperty(subindicator)
                                if relationshipEntry.action == 'SELECTED'
                                    return respondentResponses[relationshipEntry.item][subindicator] == relationshipEntry.subitem
                                if relationshipEntry.action == 'NOTSELECTED'
                                    return respondentResponses[relationshipEntry.item][subindicator] != relationshipEntry.subitem
                    else
                        if item.form == 'MULTIPLECHOICE'
                            if respondentResponses[relationshipEntry.item].hasOwnProperty(relationshipEntry.subitem)
                                if item.style == 'DRAGANDDROP'
                                    if relationshipEntry.action == 'SELECTED'
                                        return respondentResponses[relationshipEntry.item][relationshipEntry.subitem] > -1
                                    if relationshipEntry.action == 'NOTSELECTED'
                                        return respondentResponses[relationshipEntry.item][relationshipEntry.subitem] == -1
                                else
                                    if relationshipEntry.action == 'SELECTED'
                                        return respondentResponses[relationshipEntry.item][relationshipEntry.subitem]
                                    if relationshipEntry.action == 'NOTSELECTED'
                                        return !respondentResponses[relationshipEntry.item][relationshipEntry.subitem]
                        else
                            if relationshipEntry.action == 'SELECTED'
                                return respondentResponses[relationshipEntry.item] == relationshipEntry.subitem
                            if relationshipEntry.action == 'NOTSELECTED'
                                return respondentResponses[relationshipEntry.item] != relationshipEntry.subitem
                if item.type == 'INDICATOR'
                    subindicator = relationshipEntry.subitem.substr(33, 1)
                    if respondentResponses[relationshipEntry.item].hasOwnProperty(subindicator)
                        if relationshipEntry.action == 'SELECTED'
                            return respondentResponses[relationshipEntry.item][subindicator] == relationshipEntry.subitem
                        if relationshipEntry.action == 'NOTSELECTED'
                            return respondentResponses[relationshipEntry.item][subindicator] != relationshipEntry.subitem

            if relationshipEntry.action == 'SELECTED'
                return false
            if relationshipEntry.action == 'NOTSELECTED'
                return true


    isQuestionnaireItemTriggerConditionMet: (questionnaireItem, respondentResponses) ->
        triggerConditionMet = true
        firstRelationship   = true
        if questionnaireItem['relationships']
            for relationshipEntry in questionnaireItem['relationships']
                if firstRelationship
                    triggerConditionMet = this.isTriggerConditionMet(relationshipEntry, respondentResponses)
                    firstRelationship   = false
                else
                    if relationshipEntry['operator'] == 'AND'
                        triggerConditionMet  = triggerConditionMet and this.isTriggerConditionMet(relationshipEntry, respondentResponses)
                    if relationshipEntry['operator'] == 'OR'
                        triggerConditionMet  = triggerConditionMet or this.isTriggerConditionMet(relationshipEntry, respondentResponses)
        return triggerConditionMet


    isQuestionnaireItemVisible: (questionnaireItem, respondentResponses) ->
        if questionnaireItem.hasOwnProperty('relationships')
            if this.isQuestionnaireItemTriggerConditionMet(questionnaireItem, respondentResponses)
                return questionnaireItem.visibility
            else
                return !questionnaireItem.visibility
        else
            if questionnaireItem['type'] == 'SUCCESSFACTOR' or questionnaireItem['type'] == 'MATRIX'
                allSubitemsNotVisible = true
                for item in questionnaireItem['items']
                    if this.isQuestionnaireItemTriggerConditionMet(item, respondentResponses)
                        allSubitemsNotVisible = false
                if allSubitemsNotVisible
                    return false
            return questionnaireItem.visibility


    isQuestionnaireItemHeaderVisible: (questionnaireItem, respondentResponses) ->
        if questionnaireItem['type'] == 'SUCCESSFACTOR' or questionnaireItem['type'] == 'MATRIX'
            allSubitemsNotVisible = true
            for item in questionnaireItem['items']
                if this.isQuestionnaireItemTriggerConditionMet(item, respondentResponses)
                    allSubitemsNotVisible = false
            if allSubitemsNotVisible
                return false
        return true

